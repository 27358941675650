import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {IUserPaymentSystems} from "../../models/user.model";
import {environment} from "../../../../environments/environment";

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-select-payment-method-dialog',
  templateUrl: './select-payment-method-dialog.component.html',
  styleUrls: ['./select-payment-method-dialog.component.scss']
})
export class SelectPaymentMethodDialogComponent implements OnInit{
  
  selectedPs = 'fs-visa';
  
  env = environment;
  
  cryptoNonRefundAccepted: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {payment_systems: IUserPaymentSystems, is_subscription: boolean}, public dialog: MatDialog){
  }
  
  ngOnInit() {
    if(this.data.payment_systems.strp) {
      this.selectedPs = 'strp-checkout';
    } else if (!this.data.payment_systems.fs) {
      this.selectedPs = 'np';
    }
  }
  
  initiateCheckout() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'InitiateCheckout'});
  }
  
}
